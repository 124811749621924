import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import FontFaceObserver from 'fontfaceobserver';
import ImagesLoaded from 'imagesloaded';
import AOS from 'aos';

const userAgent = window.navigator.userAgent.toLowerCase();


// Promise.all(fontObservers).then(() => {

// });

let isAOSDisable = false;
if (userAgent.indexOf('msie') != -1) {
  console.log('お使いのブラウザはInternet Explorerですね！');
  isAOSDisable = true;
}

AOS.init({
  disable: isAOSDisable
});


$('.c-globalFooter__contact__pagetop').on('click', () => {
  $('body, html').animate({
    scrollTop: 0
  }, 500)
})

$('.c-globalHeader__inner__spMenu').on('click', () => {
  $('.c-globalHeader__inner__mainNavi').addClass('is-active');
})

$('.c-globalHeader__inner__mainNavi__spClose').on('click', () => {
  $('.c-globalHeader__inner__mainNavi').removeClass('is-active');
})


$('.c-globalHeader__inner__langNavi--current').on('click', (event)=> {
  event.preventDefault();
  $('.c-globalHeader__inner__langNavi__menu').slideToggle('fast');
})

// var app = new PIXI.Application(800, 600, { transparent: true });
// document.querySelector('#secret-particle').appendChild(app.view);


// var particleList = [];

// for (let index = 0; index < 30; index++) {
//   var graphics = new PIXI.Graphics();
//   graphics.lineStyle(0);
//   graphics.beginFill(0xFFFFFF, 1);
//   graphics.drawCircle(470, 90,60);
//   graphics.endFill();
//   graphics.anchor = 0.5;
//   app.stage.addChild(graphics);

//   particleList[index] = graphics;
// }



// // // Listen for animate update
// app.ticker.add((delta)=> {

//   // updateParticles(delta)
//   particleList.forEach(element => {

//     var random = Math.random(-1, 2)


//     element.vy += 1;
//     element.vx *= 0.95;
//     element.vy *= 0.95;
//     element.x += element.vx * delta;
//     element.y += element.vy * delta;


//     // element.scale.y -= 0.005 * delta;
//     // element.scale.x -= 0.005 * delta;
//     // element.alpha -= 0.008 * delta;

//   });
// });


// function createParticle (delta) {
//   for (let index = 0; index < 4; index++) {

//     const x = 800 * Math.random()
//     const y = 600 * Math.random()
//     const graphics = new PIXI.Graphics();

//     graphics.lineStyle(0);
//     graphics.beginFill(0xFFFFFF, 1);
//     graphics.drawCircle(x, y, 10);
//     graphics.endFill();
//     graphics.anchor = 0.5;

//     particleList[index] = graphics;
//     app.stage.addChild(graphics);

//     particleList[index].x += 2 * delta;
//   }
// }


// function updateParticles(delta) {
//   for (var i = 0; i < particleList.length; i++) {
//     // オブジェクトの作成
//     var particle = particleList[i];

//     particle.x += 2 * delta;

//     console.log(particle.x)

//     // // 重力
//     // particle.vy += 1;
//     // // 摩擦
//     // particle.vx *= 0.98;
//     // particle.vy *= 0.98;
//     // // 速度を位置に適用
//     // particle.x += particle.vx;
//     // particle.y += particle.vy;
//     // // 地面
//     // // 寿命を減らす
//     // particle.life -= 1;
//     // // 寿命の判定
//     // if (particle.life <= 0) {
//     //   stage.removeChild(particle);
//     //   particleList.splice(i, 1);
//     // }
//   }
// }
